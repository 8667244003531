<template>
    <v-card v-if="project && version" flat>
        <v-card-text v-if="isNotMajor && this.allowEditing && showEmpty && !version.dependencies">
            <v-row align="center" justify="center">
                <v-btn small text v-text="$t('versions.actions.get_dependencies')" @click="getFromMajor"></v-btn>
            </v-row>
        </v-card-text>
        <v-card-text>
            <markdown
                :value="version.dependencies"
                v-on:save-content="changeAttribute"
                v-on:show-editor="showEmpty = !showEmpty"
                :label="$t('versions.actions.add_dependencies')"
                :allow-editing="this.allowEditing"
            />
        </v-card-text>
        <v-card-text v-if="!version.dependencies && showEmpty" class="subtitle-1 py-8 text-center">
            {{ $t('versions.empty.dependencies') }}
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import markdown from '@/components/shared/markdown';
import { projectMemberMixin } from '@/mixins/member';

export default {
    name: 'delivery-dependencies',
    data: () => ({
        editing: false,
        editingValue: undefined,
        showEmpty: true,
    }),
    mixins: [projectMemberMixin],
    components: {
        markdown,
    },
    props: {
        version: {
            required: true,
        },
        allowEdit: {
            default: true,
        },
    },
    computed: {
        ...mapGetters(['project']),
        isNotMajor() {
            return !(this.version.status === 'major') && this.versionMajor && this.versionMajor.dependencies;
        },
        allowEditing() {
            return this.allowEdit && this.member.roles.includes('developer');
        },
        versionMajor() {
            return this.version.parent ? this.project.versions.find((v) => v.name === this.version.parent) : undefined;
        },
    },
    methods: {
        ...mapActions(['updateVersion']),
        getFromMajor() {
            this.changeAttribute(this.versionMajor.dependencies);
        },
        changeAttribute(value) {
            const versionToUpdate = {
                id: this.project.id,
                versionId: this.version.id,
                dependencies: value,
            };

            this.updateVersion(versionToUpdate);
        },
    },
};
</script>

<style></style>
