<template>
    <v-card v-if="project && version">
        <version-toolbar :version="version" v-on:back="back()" />

        <v-tabs centered grow icons-and-text>
            <v-tab href="#version-journal">
                {{ $t('versions.sections.journal') }}
                <v-icon>mdi-clipboard-text-outline</v-icon>
            </v-tab>
            <v-tab href="#version-dependencies">
                {{ $t('versions.sections.dependencies') }}
                <v-icon>mdi-link-box-outline</v-icon>
            </v-tab>
            <v-tab href="#version-note">
                {{ $t('versions.sections.note') }}
                <v-icon>mdi-ballot-outline</v-icon>
            </v-tab>
            <v-tab href="#version-files" v-if="displayVersion">
                {{ $t('versions.sections.files') }}
                <v-icon>mdi-attachment</v-icon>
            </v-tab>
            <v-tab href="#version-issues" v-if="memberRoles.includes('developer')">
                {{ $t('versions.sections.issues') }}
                <v-icon>mdi-bug</v-icon>
            </v-tab>

            <v-tab-item value="version-journal">
                <v-card-text>
                    <version-journal :version="version" />
                </v-card-text>
            </v-tab-item>
            <v-tab-item value="version-dependencies">
                <version-dependencies :version="version" />
            </v-tab-item>
            <v-tab-item value="version-note">
                <version-notes :version="version" />
            </v-tab-item>
            <v-tab-item value="version-files">
                <version-files :version="version" />
            </v-tab-item>
            <v-tab-item value="version-issues">
                <version-issues :version="version" />
            </v-tab-item>
        </v-tabs>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import versionToolbar from '@/components/versions/header';
import versionJournal from './journal';
import versionIssues from './issues';
import versionNotes from './notes';
import versionDependencies from './dependencies';
import versionFiles from './files';
import { formatDateMixin } from '@/mixins/dates';
import { projectMemberMixin } from '@/mixins/member';

export default {
    name: 'version',
    data: () => ({
        version: undefined,
    }),
    mixins: [formatDateMixin, projectMemberMixin],
    components: {
        versionToolbar,
        versionJournal,
        versionIssues,
        versionNotes,
        versionDependencies,
        versionFiles,
    },
    mounted() {
        this.version = this.project
            ? this.project.versions.find((v) => v.id === this.$route.params.version)
            : undefined;
    },
    computed: {
        ...mapGetters(['project']),
        displayVersion() {
            if (this.version) {
                if (this.version.development) {
                    return this.memberRoles.some((r) => ['productManager', 'productOwner', 'developer'].includes(r));
                } else {
                    return true;
                }
            }

            return false;
        },
    },
    watch: {
        project: function (value) {
            this.version = this.project.versions.find((v) => v.id === this.$route.params.version);
        },
    },
    methods: {
        back() {
            this.$router.push({
                name: 'project-versions',
                params: { project_id: this.project.id },
            });
        },
    },
};
</script>

<style></style>
