<template>
    <v-card v-if="project && version" flat>
        <v-card-text v-show="issues > 0">
            <issues-groups
                v-if="displayGroup"
                :view.sync="view"
                :filters.sync="filters"
                v-on:update-group="updateGroup"
            />
            <issues-view
                :view.sync="view"
                :filters.sync="filters"
                :group.sync="group"
                :allow-select="false"
                :show-footer="true"
                v-on:total-issues="updateTotalIssues"
                :allow-editing="false"
            />
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import issuesGroups from '../../../issues/issue/groups';
import issuesView from '../../../issues/issue/view';

export default {
    name: 'delivery-issues',
    data: () => ({
        displayGroup: true,
        group: undefined,
        issues: 0,
    }),
    components: {
        issuesGroups,
        issuesView,
    },
    props: {
        version: {
            required: true,
        },
    },
    computed: {
        ...mapGetters(['project']),
        view() {
            return this.project.views.find((v) => v.name === 'deliveryIssues');
        },
        filters() {
            if (this.version) {
                return [
                    {
                        field: '$versions',
                        operator: '$in',
                        value: this.version.name,
                    },
                ];
            }
            return [];
        },
    },
    methods: {
        updateGroup(group) {
            this.group = group;
        },
        updateTotalIssues(count) {
            this.issues = count;
        },
    },
};
</script>

<style></style>
