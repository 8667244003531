<template>
    <v-card v-if="project && version" flat>
        <v-card-text v-show="issues > 0">
            <issues-groups
                v-if="displayGroup"
                :view.sync="view"
                :filters.sync="filters"
                v-on:update-group="updateGroup"
            />
            <v-card-subtitle v-if="version.name && this.otherFixes.length > 0">
                {{ version.name }}
            </v-card-subtitle>
            <issues-view
                :view.sync="view"
                :filters="filtersByVersion(version.name)"
                :group.sync="group"
                :allow-select="false"
                :show-footer="false"
                v-on:total-issues="updateTotalIssues"
                :allow-editing="false"
                :show-header="false"
            />
            <div v-for="fix in otherFixes" :key="fix.id">
                <v-card-subtitle>
                    {{ fix.name }}
                </v-card-subtitle>
                <issues-view
                    :view.sync="view"
                    :filters="filtersByVersion(fix.name)"
                    :group.sync="group"
                    :allow-select="false"
                    :show-footer="false"
                    v-on:total-issues="updateTotalIssues"
                    :allow-editing="false"
                    :show-header="false"
                />
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import issuesGroups from '../../../issues/issue/groups';
import issuesView from '../../../issues/issue/view';

export default {
    name: 'delivery-journal',
    data: () => ({
        displayGroup: true,
        group: undefined,
        issues: 0,
    }),
    components: {
        issuesGroups,
        issuesView,
    },
    props: {
        version: {
            required: true,
        },
    },
    computed: {
        ...mapGetters(['project']),
        view() {
            return this.project.views.find((v) => v.name === 'deliveryJournal');
        },
        cols() {
            if (this.version && this.version.parent) {
                return ['versionTarget'];
            }
            return [];
        },
        otherFixes() {
            if (this.version.parent) {
                const allVersions = this.project.versions
                    .filter((v) => v.parent === this.version.parent)
                    .sort((a, b) => {
                        const aName = a.name.replace(/\d+/g, (n) => +n + 100000);
                        const bName = b.name.replace(/\d+/g, (n) => +n + 100000);
                        return aName.localeCompare(bName);
                    });
                const index = allVersions.findIndex((v) => v.id === this.version.id);
                return [...allVersions.slice(0, index)].reverse();
            }

            return [];
        },
        filters() {
            if (this.version) {
                let versionsToSee = [this.version.name];
                return [
                    {
                        field: 'versionTarget',
                        operator: '$in',
                        value: versionsToSee.join(','),
                    },
                ];
            }
            return [];
        },
    },
    methods: {
        updateGroup(group) {
            this.group = group;
        },
        updateTotalIssues(count) {
            this.issues = count;
        },
        filtersByVersion(name) {
            return [
                {
                    field: 'versionTarget',
                    operator: '$in',
                    value: name,
                },
            ];
        },
    },
};
</script>

<style></style>
